import React from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Spinner } from 'reactstrap';
import { Cell, Legend, Pie, PieChart,Tooltip } from 'recharts';

const TicketTypeCard = ({ticketType})=>{

    if(!ticketType){
        return
    }

    const data = [
        { name: 'Sold', value: ticketType.sold_quantity,fill: '#f4c425' },
        { name: 'Available', value: ticketType.available_quantity,fill: '#07080b' }
    ];
      
    return(
        <React.Fragment>
             <Card className="mb-3 border-0 boxshadow">
                <CardBody>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h5>{ticketType.type_name}</h5>
                            <CardTitle tag="h5">{ticketType.name}</CardTitle>
                            <CardText>Total: {ticketType.quantity}</CardText>
                            <CardText className='mb-0 text-muted'>Solid: {ticketType.total_sold_quantity}</CardText>
                            <CardText className='text-muted'>Available: {ticketType.available_quantity}</CardText>
                        </div>
                        <div>
                            <PieChart width={200} height={200}>
                                <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} label />
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.fill} />
                                    ))}
                                <Tooltip />
                                <Legend verticalAlign="bottom" height={36} />
                            </PieChart>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default TicketTypeCard;