import { 
    GET_EVENT_TICKET_TYPES_FAIL,
    GET_EVENT_TICKET_TYPES_LOADING,
    GET_EVENT_TICKET_TYPES_SUCCESS,
    GET_TICKET_TYPES_FAIL, 
    GET_TICKET_TYPES_LOADING, 
    GET_TICKET_TYPES_SUCCESS, 
    POST_DOWNLOAD_TICKET_TYPE_EXCEL_TEMPLATE_FAIL, 
    POST_DOWNLOAD_TICKET_TYPE_EXCEL_TEMPLATE_LOADING, 
    POST_DOWNLOAD_TICKET_TYPE_EXCEL_TEMPLATE_SUCCESS, 
    POST_EVENT_TICKET_TYPES_FAIL, 
    POST_EVENT_TICKET_TYPES_LOADING,
    POST_EVENT_TICKET_TYPES_SUCCESS,
    POST_ORDER_EVENT_TICKET_TYPES_FAIL,
    POST_ORDER_EVENT_TICKET_TYPES_LOADING,
    POST_ORDER_EVENT_TICKET_TYPES_SUCCESS,
    POST_UPLOAD_TICKET_TYPE_EXCEL_TEMPLATE_FAIL,
    POST_UPLOAD_TICKET_TYPE_EXCEL_TEMPLATE_LOADING,
    POST_UPLOAD_TICKET_TYPE_EXCEL_TEMPLATE_SUCCESS} from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";

export const getTicketTypes = ({system_id}) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_TICKET_TYPES_LOADING
    })

    AxiosHelper.get(`/event/ticket-types`,{
        params:{
            system_id:system_id
        }
    }).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_TICKET_TYPES_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_TICKET_TYPES_FAIL,
                payload:err?.response?.data,
            });
        }
    )
}

export const createEventTicketTypes = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_EVENT_TICKET_TYPES_LOADING
    })

    AxiosHelper.post('/event/event-ticket-types',{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_EVENT_TICKET_TYPES_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_EVENT_TICKET_TYPES_FAIL,
                payload:err?.response?.data,
            });
        }
    )
}

export const getEventTicketTypes = ({event_id}) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:GET_EVENT_TICKET_TYPES_LOADING
    })

    AxiosHelper.get(`/event/event-ticket-types?event_id=${event_id}`).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:GET_EVENT_TICKET_TYPES_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:GET_EVENT_TICKET_TYPES_FAIL,
                payload:err?.response?.data,
            });
        }
    )
}

export const orderEventTicketTypes = (payload) =>(dispatch)=> (onSuccess)=>{

    dispatch({
        type:POST_ORDER_EVENT_TICKET_TYPES_LOADING
    })

    AxiosHelper.post('/event/order-ticket-types',{payload}).then(
        (res) => {
            onSuccess(res.data);

            dispatch({
                type:POST_ORDER_EVENT_TICKET_TYPES_SUCCESS,
                payload:res.data.data,
            });
        }
    ).catch(
        (err) => {
            dispatch({
                type:POST_ORDER_EVENT_TICKET_TYPES_FAIL,
                payload:err?.response?.data,
            });
        }
    )
}

export const downloadTicketTypesTemplate = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_DOWNLOAD_TICKET_TYPE_EXCEL_TEMPLATE_LOADING
        })

        AxiosHelper.get('/event/download-ticket-type-template',{
            params:payload,
            responseType:'blob'
        }).then(
            (res) => {

                dispatch({
                    type:POST_DOWNLOAD_TICKET_TYPE_EXCEL_TEMPLATE_SUCCESS,
                    payload:res.data,
                });

                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_DOWNLOAD_TICKET_TYPE_EXCEL_TEMPLATE_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const uploadTicketTypesTemplate = (formData) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_UPLOAD_TICKET_TYPE_EXCEL_TEMPLATE_LOADING
        })

        //upload-tickets
        AxiosHelper.post('/event/upload-ticket-type-template',formData,{
            Headers:{
                'Content-Type': 'multipart/form-data'
            }
        }).then(
            (res) => {

                dispatch({
                    type:POST_UPLOAD_TICKET_TYPE_EXCEL_TEMPLATE_SUCCESS,
                    payload:res.data,
                });

                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_UPLOAD_TICKET_TYPE_EXCEL_TEMPLATE_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}