import { 
    CLEAR_POST_BROADCAST_STATE, 
    CLEAR_POST_DOWNLOAD_ATTENDEE_LIST_STATE, 
    POST_BROADCAST_FAIL, 
    POST_BROADCAST_LOADING, 
    POST_BROADCAST_SUCCESS, 
    POST_DOWNLOAD_ATTENDEE_LIST_FAIL, 
    POST_DOWNLOAD_ATTENDEE_LIST_LOADING,
    POST_DOWNLOAD_ATTENDEE_LIST_SUCCESS} from "../Constants/ActionTypes";

const Broadcasts = (state,{type,payload}) => {

    switch (type) {
        case POST_BROADCAST_LOADING:
            return{
                ...state,
                sendBroadCast:{
                    ...state.sendBroadCast,
                    loading:true,
                }
            };
        case POST_BROADCAST_SUCCESS:
            return{
                ...state,
                sendBroadCast:{
                    ...state.sendBroadCast,
                    loading:false,
                    data:payload,
                }
            };
        case POST_BROADCAST_FAIL:
            return{
                ...state,
                sendBroadCast:{
                    ...state.sendBroadCast,
                    loading:false,
                    error:payload
                }
            };
    
        case CLEAR_POST_BROADCAST_STATE:
            return {
                ...state,
                sendBroadCast:{
                    ...state.sendBroadCast,
                    loading:false,
                    data:null,
                    error:null
                }
            };
        case POST_DOWNLOAD_ATTENDEE_LIST_LOADING:
            return{
                ...state,
                downloadListState:{
                    ...state.downloadListState,
                    loading:true,
                }
            };
        case POST_DOWNLOAD_ATTENDEE_LIST_SUCCESS:
            return{
                ...state,
                downloadListState:{
                    ...state.downloadListState,
                    loading:false,
                    data:payload,
                }
            };
        case POST_DOWNLOAD_ATTENDEE_LIST_FAIL:
            return{
                ...state,
                downloadListState:{
                    ...state.downloadListState,
                    loading:false,
                    error:payload
                }
            };
    
        case CLEAR_POST_DOWNLOAD_ATTENDEE_LIST_STATE:
            return {
                ...state,
                downloadListState:{
                    ...state.downloadListState,
                    loading:false,
                    data:null,
                    error:null
                }
            };

        default:
           return false;
    }

}

export default Broadcasts;