import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../Layouts/Header'
import { Button, Card, CardBody, CardHeader, CardImg, CardText, CardTitle, Col, Container, Row, Spinner } from 'reactstrap'
import BreadCrumb from '../../Common/BreadCrumb'
import { GlobalStore } from '../../../StateStores'
import { getInvitationTemplatessAct } from '../../../Actions/Masters/InvitationTemplatesActs'
import { SERVER_BASE_URL } from '../../../Helpers/AxiosHelper'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

const EventCardTemplates = ()=>{
    const [templates, setTemplates] = useState([]);

    const {
        MasterDispatch,
        MasterState:{
            getInvitationTemplates:{loading}
        }} = useContext(GlobalStore);

    const fetchTemplates = ()=>{
        getInvitationTemplatessAct({})(MasterDispatch)((res)=>{
            if(res.status === 'Success'){
                setTemplates(res.data)
            }
        })
    }

    useEffect(()=>{
        fetchTemplates();
    },[])

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>

            <Container fluid>
                <BreadCrumb 
                    title="Event card template"
                    subTittle="List of uploaded card template"
                    pageTitle="Template"/>
                
                <Card className="border-0 mt-3">
                    <CardHeader>
                        <div className="d-flex justify-content-between">

                            <small>Total sample ({templates?.length})</small>

                            <div>
                                {loading && (
                                    <div className='d-flex justify-content-endd p-3'>
                                        <Spinner color='dark' size="sm"/>
                                    </div>
                                )}
                            </div>

                            <Button 
                                size="sm" 
                                color="success"
                                outline
                                className="rounded-0"
                                onClick={(e) => fetchTemplates()}
                            >
                                <FontAwesomeIcon icon={faRedo} />
                            </Button>

                        </div>
                    </CardHeader>
                    {/* <CardBody className="bg-light-f1a rounded-1 p-1"></CardBody> */}
                </Card>

                <Row>
                    {(templates || []).map((template) => (
                        <Col md={2} className="mb-4" key={`card-temp-id${template.template_id}`}>
                            <Card className="my-2 border-0 shadow-sm">
                                <CardImg
                                    src={`${SERVER_BASE_URL}/cdn-template-sample/${encodeURIComponent(template.template_image)}`}
                                    crossorigin="anonymous"
                                    alt={template.template_name} 
                                    className="template-image card-img-top"
                                    top
                                    width="100%"
                                />
                                <CardBody>
                                    <CardTitle tag="h5" className='p-1 text-capitalize fs-'>{template.template_name}</CardTitle>
                                    <CardText>
                                        <div className="text-primary"><small>Uploaded by {template.createdby}</small></div>
                                        <small className="text-muted">
                                            Last updated {moment.utc(template.created_at).startOf().fromNow()}
                                        </small>
                                    </CardText>
                                </CardBody>
                            </Card>

                        </Col>
                    ))}
                    
                </Row>

            </Container>
        </React.Fragment>
    )
}

export default EventCardTemplates;