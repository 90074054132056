import { 
    POST_BROADCAST_FAIL, 
    POST_BROADCAST_LOADING, 
    POST_BROADCAST_SUCCESS, 
    POST_DOWNLOAD_ATTENDEE_LIST_FAIL, 
    POST_DOWNLOAD_ATTENDEE_LIST_LOADING,
    POST_DOWNLOAD_ATTENDEE_LIST_SUCCESS} from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";

export const BroadcastToSender = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_BROADCAST_LOADING
        })
    
        AxiosHelper.post(`/broadcast/send`,{payload}).then(
            (res) => {
    
                dispatch({
                    type:POST_BROADCAST_SUCCESS,
                    payload:res.data.data,
                });
                
                resolve(res.data)
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_BROADCAST_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const downloadAttendeeListManager = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: POST_DOWNLOAD_ATTENDEE_LIST_LOADING
        });

        // Add responseType: 'blob' for file downloads
        AxiosHelper.post('/broadcast/download-manager', { payload }, {
            responseType: 'blob'
        }).then(async res => {
            const contentType = res.headers['content-type'];
            let fileExtension = '';
            let defaultFileName = `download_${Date.now()}`;

            // Determine file type (ZIP or Excel)
            if (contentType === 'application/zip') {
                fileExtension = '.zip';
            } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileExtension = '.xlsx';
            } else {
                // If it's not a file, handle as a regular JSON response
                dispatch({
                    type: POST_DOWNLOAD_ATTENDEE_LIST_SUCCESS,
                    payload: res.data
                });
                return resolve(res.data);
            }

            // Get filename from response headers, or use default
            const filename = res.headers['content-disposition']
                ? res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
                : `${defaultFileName}${fileExtension}`;

            // Create Blob and download file
            const blob = new Blob([res.data], { type: contentType });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            //Add the logic for sending request for updating download count but consider ZIP file
            if (fileExtension === '.zip') {
                try {

                    await countEventCardDownloads(payload?.recipients)(dispatch);

                } catch (updateError) {
                    console.error('Error updating download count:', updateError);
                }
            }

            dispatch({
                type: POST_DOWNLOAD_ATTENDEE_LIST_SUCCESS,
                payload: { message: 'Download completed successfully' }
            });

            resolve({ status: true, message: 'Download completed successfully' });
        }).catch(err => {
            // Handle blob error response
            if (err.response?.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const errorData = JSON.parse(reader.result);
                        dispatch({
                            type: POST_DOWNLOAD_ATTENDEE_LIST_FAIL,
                            payload: errorData
                        });
                        reject(errorData);
                    } catch (e) {
                        dispatch({
                            type: POST_DOWNLOAD_ATTENDEE_LIST_FAIL,
                            payload: { message: 'Download failed' }
                        });
                        reject({ message: 'Download failed' });
                    }
                };
                reader.onerror = () => {
                    dispatch({
                        type: POST_DOWNLOAD_ATTENDEE_LIST_FAIL,
                        payload: { message: 'Download failed' }
                    });
                    reject({ message: 'Download failed' });
                };
                reader.readAsText(err.response.data);
            } else {
                dispatch({
                    type: POST_DOWNLOAD_ATTENDEE_LIST_FAIL,
                    payload: err?.response?.data
                });
                reject(err);
            }
        });
    });
};

export const countEventCardDownloads = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
    
        AxiosHelper.post(`/broadcast/update-download-count`,{payload}).then(
            (res) => {
                
                resolve(res.data)
            }
        ).catch(
            (err) => {
                reject(err)
            }
        )
    })
}