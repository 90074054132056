import AxiosHelper from "../../Helpers/AxiosHelper";


export const getSystemVisitors = () =>(dispatch)=>{

    return new Promise ((resolve,reject)=>{
        AxiosHelper.get(`/visitors/`).then(
            (res) => {
                resolve(res.data);
            }
        ).catch(
            (err) => {
                reject(err?.response?.data)
            }
        )
    })

}

export const getSystemVisitorStats = () =>(dispatch)=>{

    return new Promise ((resolve,reject)=>{
        AxiosHelper.get(`/visitors/stats`).then(
            (res) => {
                resolve(res.data);
            }
        ).catch(
            (err) => {
                reject(err?.response?.data)
            }
        )
    })

}