import { Outlet, createBrowserRouter } from "react-router-dom"
import Login from "../Components/Pages/Login"
import Dashboard from "../Components/Pages/Dashboard";
import UsersList from "../Components/Pages/Users/UsersList";
import Categories from "../Components/Pages/Category/Categories";
import EventList from "../Components/Pages/Events/EventList";
import { Protected, RouteAuth } from "../Helpers/permissionHandler";
import EventViewer from "../Components/Pages/EventViewer";
import Calendar from "../Components/Pages/Calendar";
import EventApprovals from "../Components/Pages/Events/EventApprovals";
import UserGroups from "../Components/Pages/Usergroup/UserGroups";
import OnlineRsvp from "../Components/Pages/OnlineRsvp";
import EventOnlineMenuOrder from "../Components/Pages/FoodMenuManager/EventOnlineMenuOrder";
import UserProfile from "../Components/Pages/Users/UserProfile";
import DeactivateAccountDescription from "../Components/Pages/Users/DeactivateAccountDescription";
import { ACCESS_EVENT_DASHBOARD, EVENT_APPROVALS, EVENT_MASTER_LIST, VIEW_CATEGORIES, VIEW_EVENT_CALENDAR, VIEW_EVENTS, VIEW_USERS, VIEW_USERSGROUPS } from "./PermissionTypes";
import Basic503 from "../Components/Common/Errors/Basic503";
import Basic404 from "../Components/Common/Errors/Basic404";
import EventMasterList from "../Components/Pages/Events/EventMasterList";
import EventCardTemplates from "../Components/Pages/InvitationTemplates/EventCardTemplates";
import VisitorsAnalytics from "../Components/Pages/Analytics/VisitorsAnalytics";

const RoutesMaster = (isAuthenticated,userData)=>{
    return createBrowserRouter([
        {
            path:'/',
            element:isAuthenticated? <RouteAuth LoggedUserProfile={userData}/>:<Login/>,
            errorElement:<Basic404/>,
            children:[
                {
                    path:'/login',
                    element:<Login/>
                },{
                    path:'/logout',
                    element:<Login/>,
                },{
                    path:'/',
                    element:<Login/>,
                }
            ]
        },{
            path:'/dashboard',
            errorElement:<Basic404/>,
            element:<Protected
            isSignedIn={isAuthenticated}
            bypass={true}
            >
                <Dashboard/>
            </Protected>
        },{
            path:'/unauthorized',
            element:<Basic503/>
        },{
            path:'/dashboard/users',
            element:<Outlet/>,
            errorElement:<Basic404/>,
            children:[
                {
                    path:'/dashboard/users',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    action={VIEW_USERS}
                    >
                        <UsersList/>
                    </Protected>
                },{
                    path:'/dashboard/users/groups',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    action={VIEW_USERSGROUPS}
                    >
                        <UserGroups/>
                    </Protected>
                },{
                    path:'/dashboard/users/profile',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    bypass={true}
                    >
                        <UserProfile/>
                    </Protected>
                },
            ]
        },{
            path:'/dashboard/categories',
            errorElement:<Basic404/>,
            element:<Protected
            isSignedIn={isAuthenticated}
            action={VIEW_CATEGORIES}
            >
                <Categories/>
            </Protected>
        },{
            path:'/dashboard/events',
            errorElement:<Basic404/>,
            element:<Outlet/>,
            children:[
                {
                    path:'/dashboard/events',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    action={VIEW_EVENTS}
                    >
                        <EventList/>
                    </Protected>,
                },
                {
                    path:'/dashboard/events/viewer',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    action={ACCESS_EVENT_DASHBOARD}
                    >
                        <EventViewer/>
                    </Protected>
                },{
                    path:'/dashboard/events/calendar',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    action={VIEW_EVENT_CALENDAR}
                    >
                        <Calendar/>
                    </Protected>
                },{
                    path:'/dashboard/events/approvals',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    action={EVENT_APPROVALS}
                    >
                        <EventApprovals/>
                    </Protected>
                },{
                    path:'/dashboard/events/masters',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    action={EVENT_MASTER_LIST}
                    >
                        <EventMasterList/>
                    </Protected>
                }
            ]
        },{
            path:'/dashboard/card-templates',
            errorElement:<Basic404/>,
            element:<Outlet/>,
            children:[
                {
                    path:'/dashboard/card-templates',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    bypass
                    >
                        <EventCardTemplates/>
                    </Protected>
                }
            ]
        },{
            path:'/dashboard/analtics',
            errorElement:<Basic404/>,
            element:<Outlet/>,
            children:[
                {
                    path:'/dashboard/analtics/visitors',
                    element:<Protected 
                    isSignedIn={isAuthenticated}
                    bypass
                    >
                        <VisitorsAnalytics/>
                    </Protected>
                }
            ]
        },{
            path:'/rsvp',
            errorElement:<Basic404/>,
            children:[
                {
                    path:'/rsvp/online/:qrcode',
                    element:<OnlineRsvp/>
                }
            ]
        },{
            path:'event-menu/:tableNumber?',
            element:<EventOnlineMenuOrder/>
        },{
            path:'/deactivate-account',
            element:<DeactivateAccountDescription/>,
        }
    ])
}

export default RoutesMaster;