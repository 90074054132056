import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { createEvents, getEventCategories } from '../../../Actions/Masters/EventCategoriesActs';
import DateFilter from '../../Common/DateFilter';
import moment from 'moment';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const EventCreate = ({ selectedCategory = null, setSelectedCategory = () => {}, ctrlModal = () => {}, selectedEvent = null,forApproval=false, refreshList=false })=>{
    const [formValid, setFormValid] = useState(false);
    const [form,setForm] = useState({
        event_name:'',
        contact_name:'',
        contact_mobile:'',
        description:'',
        location:'',
        from_date:moment(),
        to_date:moment(),
        category_id:selectedCategory?.id || '',
        system_id: selectedCategory.invitation_system_id
    });

    const [formErrors,setFormErrors] = useState({
        event_name:'Event name is required',
        contact_name:'Contact name is required',
        contact_mobile:'Contact mobile is required',
        description:'Description is required',
        location:'Location is required',
        category_id:'Category is required',
        from_date:'Date is required',
    });

    const {
        MasterDispatch,
        MasterState:{
            createEvent:{data,error,loading},
            getEventCategories:{data:EventData,error:EventError,loading:EventLoading}
        }} = useContext(GlobalStore);

    useEffect(() => {
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);


    useEffect(()=>{
        function fetchCategoriesData(){
            getEventCategories()(MasterDispatch)((res)=>{})
        }
        fetchCategoriesData();
    },[])

    useEffect(() => {
        if (selectedEvent && Object.keys(selectedEvent).length > 0) {

            const selectedObj = EventData.filter((item)=> item.id === parseInt(selectedEvent.category_id))[0];

            setForm({
                id:selectedEvent.id,
                event_name: selectedEvent.event_name || '',
                contact_name: selectedEvent.contact_name || '',
                contact_mobile: selectedEvent.contact_mobile || '',
                description: selectedEvent.description || '',
                location: selectedEvent.location || '',
                from_date: moment(selectedEvent.from_date) || moment(),
                to_date: moment(selectedEvent.to_date) || moment(),
                category_id: selectedEvent.category_id || '',
                system_id: selectedEvent.system_id || selectedObj.invitation_system_id
            });

            setSelectedCategory({
                id: selectedEvent.category_id,
                name: selectedEvent.cat_name,
            });

            setFormErrors({
                event_name: '',
                contact_name: '',
                contact_mobile: '',
                description: '',
                location: '',
                category_id: '',
                from_date: ''
            });
        }
    }, [selectedEvent]);


    useEffect(()=>{
        if (selectedCategory?.id) {

            handleDefaultCategory({
                category_id: selectedCategory.id,
                system_id: selectedCategory.invitation_system_id
            });
        }

    },[selectedCategory])

    const handleDefaultCategory = (fields) => {
        setForm(prev => ({ ...prev, ...fields }));

        // Clear errors for all fields
        const clearedErrors = Object.keys(fields).reduce((acc, key) => {
            acc[key] = ''; 
            return acc;
        }, {});
        
        setFormErrors(prev => ({ ...prev, ...clearedErrors }));
    };




    const onChange = ({name,e}) => {
        let value;
        if(name === 'from_date' || name === 'to_date' || name === 'contact_mobile'){
            value = e;
        }else{
            value = e.target.value;
        }

        setForm((prev) => ({ ...prev, [name]: value }));

        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
            if (name === 'category_id') {

                const selectedObj = EventData.filter((item)=> item.id === parseInt(e.target.value))[0];

                const catObj = {
                  id: e.target.value,
                  name: e.target.options[e.target.selectedIndex].text,
                  system_name:selectedObj.system_name
                };

                setSelectedCategory(catObj);
                //Update invitation system.
                setForm((prev)=>({
                    ...prev,
                    system_id:selectedObj.invitation_system_id}))
              }
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }

    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if(formValid){
            createEvents(form)(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    toast.success(res.message)
                }else{
                    toast.error(res.message)
                }
            })
            ctrlModal()
        }
    }


    return(
        <React.Fragment>
            <div className=''>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Label>Event name</Label>
                            <Input 
                                type='text'
                                placeholder='Event name'
                                className="rounded-0"
                                value={form?.event_name}
                                onChange={(e) => onChange({name:'event_name',e})}
                                valid={formErrors.event_name === ''}
                                invalid={formErrors.event_name !== ''}
                                />
                                {formErrors.event_name && <FormFeedback>{formErrors.event_name}</FormFeedback>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Contact name</Label>
                            <Input 
                                type='text'
                                placeholder='Contact name'
                                className="rounded-0"
                                value={form?.contact_name}
                                onChange={(e) => onChange({name:'contact_name',e})}
                                valid={formErrors.contact_name === ''}
                                invalid={formErrors.contact_name !== ''}
                                />
                                {formErrors.contact_name && <FormFeedback>{formErrors.contact_name}</FormFeedback>}
                        </Col>
                        <Col>
                            <Label htmlFor="basiInput" className="form-label">Contact mobile</Label>
                            <PhoneInput 
                                defaultCountry="TZ"
                                className="form-control rounded-0"
                                valid={formErrors.contact_mobile === ''}
                                invalid={formErrors.contact_mobile !== ''}
                                value={form?.contact_mobile || ""}
                                    onChange={(e) => onChange({name:'contact_mobile',e})}
                            />
                            {formErrors.contact_mobile && <FormFeedback>{formErrors.contact_mobile}</FormFeedback>}
                        </Col>
                        <Col>
                            <Label>Category</Label>
                            <Input 
                                type='select'
                                placeholder='Contact mobile'
                                className="rounded-0"
                                value={form?.category_id}
                                onChange={(e) => onChange({name:'category_id',e})}
                                valid={formErrors.category_id === ''}
                                invalid={formErrors.category_id !== ''}>
                                    <option defaultValue="">--Select--</option>
                                    {(EventData || []).map((category,index) =>(
                                        <option key={index} value={category.id}>{category.name}</option>
                                    ))}
                            </Input>
                            {formErrors.category_id && <FormFeedback>{formErrors.category_id}</FormFeedback>}
                            {selectedCategory?.system_name && <small><strong>System Used:</strong> {selectedCategory?.system_name}</small>}
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Label>Event date</Label>
                            <div className='mb-2'>
                                <DateFilter
                                    startDate={form?.from_date}
                                    endDate={form?.to_date}
                                    onDatesChange={({ startDate, endDate }) => {
                                        onChange({name:'from_date',e:startDate});
                                        onChange({name:'to_date',e:endDate})
                                    }}
                                />
                            </div>
                            {formErrors.from_date && <FormFeedback>{formErrors.from_date}</FormFeedback>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Event description</Label>
                            <Input 
                                type='textarea'
                                placeholder='Description'
                                className="rounded-0"
                                value={form?.description}
                                onChange={(e) => onChange({name:'description',e})}
                                valid={formErrors.description === ''}
                                invalid={formErrors.description !== ''}
                                />
                            {formErrors.description && <FormFeedback>{formErrors.description}</FormFeedback>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Event location / Address</Label>
                            <Input 
                                type='textarea'
                                placeholder='Address'
                                className="rounded-0"
                                value={form?.location}
                                onChange={(e) => onChange({name:'location',e})}
                                valid={formErrors.location === ''}
                                invalid={formErrors.location !== ''}
                                />
                            {formErrors.location && <FormFeedback>{formErrors.location}</FormFeedback>}
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            {!forApproval && (
                                <>
                                {selectedEvent?.is_event_passed ? (
                                    <small className='text-danger'>{selectedEvent?.event_status}</small>
                                ):(
                                    <Button 
                                    disabled={!formValid} 
                                    color="dark" type="submit" className="custom-button rounded-0">
                                        <FontAwesomeIcon icon={faSave}/> {selectedEvent?.id? 'Update':'Save'}
                                    </Button>
                                )}
                                </>
                            )}
                        </Col>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    )
}


EventCreate.propTypes = {
    selectedCategory: PropTypes.object,
    setSelectedCategory: PropTypes.func,
    ctrlModal: PropTypes.func,
    selectedEvent: PropTypes.object,
};

export default EventCreate;