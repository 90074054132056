export const VIEW_CATEGORIES = 'Categories';
export const CREATE_USER = 'Create user';
export const VIEW_USERS = 'Users';
export const EDIT_USER = 'Edit User';
export const CHANGE_USER_CURRENT_GROUP = 'Change current user group';
export const INACTIVE_USER = 'Inactive user';
export const RESET_USER_PASSWORD = 'Reset user password';
export const VIEW_USERSGROUPS = 'Users groups';
export const VIEW_EVENTS = 'Events';
export const CREATE_EVENTS = 'Create Event';
export const DELETE_EVENT = 'Delete event';
export const VIEW_EVENT_CALENDAR = 'Calendar';
export const EVENT_APPROVALS = 'Event approvals';
export const EDIT_EVENT = 'Edit Event';
export const EVENT_MASTER_LIST = 'Event Master List';
export const VERIFY_CARD_CODE = 'Verify Card Code';
export const EDIT_USERS_GROUP = 'Edit Users group';
export const USER_BULK_UPLOAD = 'User bulk upload';
export const BANNER_MANAGER = 'Banner manager';
export const CREATE_FOOD_MENU_CATEGORY = 'Create Food Menu category';
export const CREATE_FOOD_MENU_ITEMS = 'Create Food Menu items';
export const CREATE_TICKET_TYPE = 'Create ticket type';
export const ASSIGN_EVENT_CARD_TO_GUEST = 'Assign event card to guest';
export const SET_EVENT_CARD_TEMPLATE = 'Set event card template';
export const CREATE_EVENT_SEATING_TABLE = 'Create event seating table';
export const CREATE_EVENT_MENU = 'Create event menu';
export const ACCESS_EVENT_GUEST_MANAGER = 'Access event guest manager';
export const SHARE_CARD_TO_WHATSAPP = 'Share card to WhatsApp';
export const SHARE_CARD_TO_SMS = 'Share card to SMS';
export const SHARE_CARD_TO_DOWNLOADS = 'Share card to Downloads';
export const SEND_REMINDER = 'Send reminder';
export const ACCESS_EVENT_DASHBOARD = 'Access event dashboard';
export const VIEW_ALL_UPLOADED_EVENT_CARD_TEMPLATES = 'View uploaded event card templates';
export const UPLOAD_EVENT_CARD_TEMPLATES = 'Upload event card template';
export const VIEW_VISITORS_ANALTICS_DASHBOARD = 'Visitors Analytics Dashboard';



/**
 * Defined groups for controlling them together
 */

/**
 * Group of event tab rights
 */
export const EVENT_TAB_RIGHTS = [
    VIEW_EVENTS,
    CREATE_EVENTS,
    VIEW_EVENT_CALENDAR,
    EVENT_APPROVALS,
    EVENT_MASTER_LIST,
    VERIFY_CARD_CODE
]

/**
 * Group of event food menu rights
 */
export const FOOD_MENU_TAB_RIGHTS = [
    CREATE_FOOD_MENU_CATEGORY,
    CREATE_FOOD_MENU_ITEMS
]

/**
 * Group of system setup tab rights
 */
export const SYSTEM_SETUP_TAB_RIGHTS = [
    VIEW_CATEGORIES,
    VIEW_USERS,
    VIEW_USERSGROUPS,
    USER_BULK_UPLOAD,
    BANNER_MANAGER
]

/**
 * Group of event card tab rights
 */
export const EVENT_CARD_TEMPLATE_TAB_RIGHTS = [
    VIEW_ALL_UPLOADED_EVENT_CARD_TEMPLATES,
    UPLOAD_EVENT_CARD_TEMPLATES
]

/**
 * Group of analytics tab rights
 */
export const SYSTEM_ANALYTICS_TAB_RIGHTS = [
    VIEW_VISITORS_ANALTICS_DASHBOARD,
]