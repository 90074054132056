import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { getEventTicketTypes, orderEventTicketTypes } from '../../../Actions/Masters/TicketTypesActs';
import { Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Container, Input, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faMinus, faPlus, faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import UserMasterSearch from '../Users/UserMasterSearch';
import BulkCardImportation from './BulkCardImportation';
import moment from 'moment';

const OrderTicketsForm = ({selectedEvent})=>{
    const [eventTicketTypes, setEventTicketTypes] = useState([]);
    const [selectedTickets, setSelectedTickets] = useState({});
    const [qrCodes, setQrCodes] = useState([]);
    const [showTickets, setShowTickets] = useState(true);
    const [selectedUser, setSelectedUser] = useState({});
    const [activeTab, setActiveTab] = useState('1');

    const {
        MasterDispatch,
        MasterState:{
            orderEventTicketTypes:{loading},
            getEventTicketTypes:{data:ticketTypeData,error:ticketTypeError,loading:ticketTypeLoading},
        }} = useContext(GlobalStore);


    useEffect(()=>{
        function fetchEventTicketTypes(){
            if(selectedEvent?.id !== undefined){
                getEventTicketTypes({event_id:selectedEvent?.id})(MasterDispatch)((res)=>{
                    if(res.status === 'Success'){
                        setEventTicketTypes(res.data)
                    }
                })
            }
        }
        fetchEventTicketTypes()
    },[])

    const handleQuantityChange = (ticketTypeId, change) => {
        setSelectedTickets(prevState => {
            const newQuantity = (prevState[ticketTypeId] || 0) + change;
            return {
                ...prevState,
                [ticketTypeId]: Math.max(0, newQuantity)
            };
        });
    };

    const isPurchaseDisabled = !Object.values(selectedTickets).some(
        quantity => quantity > 0
      ) || !selectedUser?.value;

    const handleOrderTicket = ()=>{
        const purchaseData = Object.keys(selectedTickets).map(ticketTypeId => ({
            ticketTypeId,
            quantity: selectedTickets[ticketTypeId]
        }));

        if(!isPurchaseDisabled){
            orderEventTicketTypes({
                user_id:selectedUser?.value,
                event_id:selectedEvent?.id,
                tickets:purchaseData})(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    setQrCodes(res.data)

                    setEventTicketTypes([]);
                    setShowTickets(false);
                }else{
                    toast.error(res.message)
                }
            })
        }

    }

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    return(
        <React.Fragment>
            <Container>

                <Row>
                    <Col>
                        <h4 className='mb-0'>{selectedEvent?.event_name}</h4>
                        <p className='mb-0'><small className='text-muted'>{selectedEvent?.description}</small></p>
                        <p>
                            <small>{moment.utc(selectedEvent.from_date).format('DD-MM-YYYY')}</small>
                            <small className='text-primary ms-2'> - {selectedEvent?.event_status}</small>
                        </p>
                        <hr/>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Nav tabs>
                            <NavItem>
                                <NavLink 
                                    href="#"
                                    className={activeTab === '1' ? 'active' : ''}
                                    onClick={() => { toggleTab('1'); }}>
                                    <FontAwesomeIcon icon={faPlusCircle}/> Single Card Entry
                                    
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink 
                                    href="#"
                                    className={activeTab === '2'? 'active':''}
                                    onClick={() => { toggleTab('2'); }}>
                                    <FontAwesomeIcon icon={faFileExcel}/> Bulk Card Import
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab} >
                            <TabPane tabId="1">
                                <Card className='mt-4'>
                                    <CardBody>
                                        <UserMasterSearch onSelect={setSelectedUser} evetData={selectedEvent}/>

                                        <h5>Purchase Tickets</h5>

                                            {loading && (
                                            <div className='d-flex justify-content-center p-4'>
                                                <Spinner/>
                                            </div>)}

                                            {!loading && qrCodes.length > 0 && (
                                                <div className='d-flex justify-content-center'>
                                                    <div>
                                                        {(qrCodes || []).map((qrcode,index) => (
                                                            <Card className='mb-2'>
                                                                <CardBody>
                                                                    <QRCode value={qrcode.encodedQrcode}/>
                                                                    {/* <img src={qrcode.qrCode} alt={`QR Code ${index}`} className="img-fluid" /> */}
                                                                    </CardBody>
                                                                <CardFooter>{`${qrcode.quantity} X ${qrcode.ticketTypeName}`}</CardFooter>
                                                            </Card>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            {showTickets && (
                                                
                                                <>
                                                {(eventTicketTypes || []).map(ticketType => (
                                                    <Row>
                                                        <Col key={ticketType.event_ticket_type_id} className="mb-3">
                                                            <Card className='rounded-0'>
                                                                <CardBody>
                                                                    <CardTitle tag="h5">{ticketType.type_name}</CardTitle>
                                                                    <Row>
                                                                        <Col>
                                                                            <CardSubtitle className="mb-2 text-muted">Price: {ticketType.price}</CardSubtitle>
                                                                            <CardSubtitle className="mb-2 text-muted">Purchased: {ticketType.quantity}</CardSubtitle>
                                                                            <CardSubtitle className="mb-2 text-muted">Solid: {ticketType.total_sold}</CardSubtitle>
                                                                            <CardSubtitle className="mb-2 text-muted">Available: {ticketType.available_quantity}</CardSubtitle>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <Button
                                                                                    size="sm" 
                                                                                    color='primary'
                                                                                    outline
                                                                                    className='rounded-0'
                                                                                    onClick={() => handleQuantityChange(ticketType.event_ticket_type_id, -1)} 
                                                                                    disabled={!selectedTickets[ticketType.event_ticket_type_id]}
                                                                                ><FontAwesomeIcon icon={faMinus}/>
                                                                                </Button>
                                                                                <Input
                                                                                    type="number" 
                                                                                    value={selectedTickets[ticketType.event_ticket_type_id] || 0} 
                                                                                    readOnly 
                                                                                    size="sm"
                                                                                    className="text-center mx-2 rounded-0"
                                                                                    style={{ width: '50px' }}
                                                                                />
                                                                                <Button 
                                                                                    size="sm" 
                                                                                    outline
                                                                                    color='success'
                                                                                    className='rounded-0'
                                                                                    onClick={() => handleQuantityChange(ticketType.event_ticket_type_id, 1)}
                                                                                ><FontAwesomeIcon icon={faPlus}/>
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                ))}

                                                {selectedEvent.is_event_passed ? (
                                                    <small className='text-danger'>{selectedEvent.event_status}</small>
                                                ):(
                                                    <div className='d-flex justify-content-end'>
                                                        <Button 
                                                            color="dark" 
                                                            className="custom-button rounded-0"
                                                            disabled={isPurchaseDisabled}
                                                            onClick={handleOrderTicket}><FontAwesomeIcon icon={faSave}/> Save</Button>
                                                    </div>
                                                )}
                                                </>
                                                
                                            )}
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId="2">
                                <BulkCardImportation eventData={selectedEvent}/>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    )
}

export default OrderTicketsForm;