import React, { useContext, useRef, useState } from 'react'
import { GlobalStore } from '../../../StateStores';
import { Button, Card, CardBody, Col, Input, InputGroup, Row, Spinner, Table } from 'reactstrap';
import AxiosHelper from '../../../Helpers/AxiosHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { downloadTicketTypesTemplate, uploadTicketTypesTemplate } from '../../../Actions/Masters/TicketTypesActs';
import QRCode from 'qrcode.react';

const BulkCardImportation = ({eventData})=>{
    const [selectedFile, setSelectedFile] = useState(null);
    const [soldTickets, setSoldTickets] = useState([]);
    const fileInputRef = useRef(null);
    const {MasterDispatch,MasterState:{
        downloadEventTicketTypeTemplateState:{loading:loadingSampleExcel},
        uploadEventTicketTypeTemplateState:{loading:loadingUploadBulkExcel}
    }} = useContext(GlobalStore);

    const handleDownloadTemplate = async () => {
        try {
             const response = await downloadTicketTypesTemplate(eventData)(MasterDispatch);
      
            // Create blob from response
            const blob = new Blob([response], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
            });
            
            // Create download link
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `event_name_${eventData?.name}_id_${eventData?.id}_template.xlsx`);
            
            // Trigger download
            document.body.appendChild(link);
            link.click();
            
            // Cleanup
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            toast.error('Download failed:', error)
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadTickets = async () => {

        if (!selectedFile) {
            toast.error('Please select a file to upload.');
            return;
        }

        try {

            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('event_data', JSON.stringify(eventData)); 

            const response = await uploadTicketTypesTemplate(formData)(MasterDispatch);

            if (response.status) {
                toast.success(response.message);
                setSoldTickets(response.data);
                setSelectedFile(null)

                if(fileInputRef.current){
                    fileInputRef.current.value = "";
                }
            } else {
                if (response.errors && response.errors.length > 0) {
                    // Display validation errors
                    response.errors.forEach(error => toast.error(error));
                } else {
                    toast.error(response.message || 'Upload failed');
                }
            }
            
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Error uploading tickets');
        }
    };

    return(
        <React.Fragment>
            <Card className='mt-4'>
                <CardBody>
                    <Row>
                        <Col>
                            {/* <h3>Purchase Tickets</h3> */}
                            <Button 
                                color="dark" 
                                size='sm' 
                                // disabled={eventData?.is_event_passed || loadingUploadBulkExcel}
                                disabled={loadingUploadBulkExcel || eventData?.is_event_passed} 
                                className="custom-button rounded-0" 
                                onClick={handleDownloadTemplate}>
                                <FontAwesomeIcon icon={faDownload} className="mr-2"/> Download Excel Template
                            </Button>
                            {eventData?.is_event_passed && <small className='text-danger ms-2'>{eventData?.event_status}</small>}
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <InputGroup>
                                <Input 
                                    size="sm" 
                                    type="file"
                                    innerRef={fileInputRef}
                                    onChange={handleFileChange} className="mt-2" />
                                <Button 
                                    color="dark"
                                    className="mt-2 custom-button rounded-0" 
                                    onClick={handleUploadTickets}
                                    size='sm'
                                    disabled={loadingUploadBulkExcel || eventData?.is_event_passed || !selectedFile}
                                    >
                                    {loadingUploadBulkExcel ? "Uploading..." : "Upload Tickets"}
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr/>

                    {loadingUploadBulkExcel && (
                    <div className='d-flex justify-content-center p-4'>
                        <Spinner/>
                    </div>)}

                    {soldTickets.length > 0 && !loadingSampleExcel && (
                        <Row>
                            <Col>
                                <Table bordered size="sm">
                                    <thead className="bg-light">
                                        <tr>
                                            <th>SN</th>
                                            <th>Attendee</th>
                                            <th>Mobile</th>
                                            <th>Card type</th>
                                            <th className='text-center'>Quantity</th>
                                            <th className='text-center'>Card code</th>
                                            <th>Qrcode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(soldTickets || []).map((ticket,index)=>(
                                            <tr key={index}>
                                                <td>{++index}</td>
                                                <td>{`${ticket?.firstName} ${ticket?.middleName} ${ticket?.lastName}`}</td>
                                                <td>{ticket?.mobile}</td>
                                                <td>{ticket?.type_name}</td>
                                                <td className='text-center'>{ticket?.card_qty}</td>
                                                <td className='text-center'>{ticket?.card_code}</td>
                                                <td className="text-center">
                                                    <QRCode value={ticket.qrcode} size={20} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )}

                    
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default BulkCardImportation;