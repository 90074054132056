import React, { useState, useRef, useEffect } from 'react';
import { 
  FormGroup, 
  Input, 
  Label, 
  Button, 
  Card, 
  CardBody,
  Row,
  Col,
  Collapse,
  InputGroup,
  Alert
} from 'reactstrap';

const MessageCompose = ({ type, form, setForm, defaultContactNumber = "+255718555860" }) => {
  const [cursorPosition, setCursorPosition] = useState(0);
  const [contactNumber, setContactNumber] = useState(defaultContactNumber);
  const [isOpen, setIsOpen] = useState(false);
  const [isPlaceholderOpen, setIsPlaceholderOpen] = useState(false);
  const textareaRef = useRef(null);
  
  const placeholders = [
    { key: 'recipient_name', label: 'Recipient Name' },
    { key: 'event_name', label: 'Event Name' },
    { key: 'event_date', label: 'Event Date' },
    { key: 'card_code', label: 'Card Code' },
    { key: 'contact_number', label: 'Contact Number', value: contactNumber }
  ];

  const sampleMessages = {
    sms: `Habari {{recipient_name}},Tunayo furaha kukualika kwenye sherehe ya {{event_name}} mnamo Tarehe {{event_date}}. kadi yako ni {{card_code}}. Kwa msaada wowote tupigie kwa {{contact_number}}.`,
    reminder: `Hello {{recipient_name}},We are happy to invite you to the {{event_name}} event on {{event_date}}. Your card code is {{card_code}}. For any assistance, please call us at {{contact_number}}.`
  };


  useEffect(() => {
    if (type && sampleMessages[type]) {
      const messageWithContact = sampleMessages[type].replace(
        /{{contact_number}}/g,
        contactNumber
      );
      setForm(prev => ({ ...prev, message: messageWithContact }));
    }
  }, [type]);


  useEffect(() => {
    if (form.message) {
      const updatedMessage = form.message.replace(
        /{{contact_number}}/g,
        contactNumber
      );
      setForm(prev => ({ ...prev, message: updatedMessage }));
    }
  }, [contactNumber]);

  const resetToDefaultNumber = () => {
    setContactNumber(defaultContactNumber);
  };

  const insertPlaceholder = (placeholder) => {
    if (!textareaRef.current) return;

    const start = textareaRef.current.selectionStart;
    const end = textareaRef.current.selectionEnd;
    const text = form.message;
    const before = text.substring(0, start);
    const after = text.substring(end);
    
    const formattedPlaceholder = `{{${placeholder}}}`;
    const newText = before + formattedPlaceholder + after;
    setForm(prev => ({ ...prev, message: newText }));
    
    setTimeout(() => {
      textareaRef.current.focus();
      const newPosition = start + formattedPlaceholder.length;
      textareaRef.current.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  const handleTextareaChange = (e) => {
    setForm(prev => ({ ...prev, message: e.target.value }));
    setCursorPosition(e.target.selectionStart);
  };

  const handleContactChange = (e) => {
    setContactNumber(e.target.value);
  };

  const toggle = () => setIsOpen(!isOpen);
  const togglePlaceholder = () => setIsPlaceholderOpen(!isPlaceholderOpen);

  return (
    <Card className="mb-2">
      <CardBody>
        {/* <Alert color="success" className="mb-3">
          Messages sent successfully!
        </Alert> */}
        <Row className="mb-1">
          <Col>
            <Button
              color="light"
              onClick={toggle}
              className="mb-1 d-flex align-items-center"
              size="sm"
            >
              <span className="me-2">Support Contact Settings</span>
              <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
            </Button>
            
            <Collapse isOpen={isOpen}>
              <Card className="border">
                <CardBody className="py-2">
                  <FormGroup className="mb-2">
                    <Label for="contactNumber" className="text-muted fs-12">
                      Support Contact Number
                    </Label>
                    <InputGroup>
                      <Input
                        type="text"
                        name="contactNumber"
                        id="contactNumber"
                        value={contactNumber}
                        onChange={handleContactChange}
                        placeholder="Enter support contact number"
                      />
                      <Button 
                        color="secondary" 
                        outline 
                        onClick={resetToDefaultNumber}
                        title="Reset to default number"
                      >
                        Reset
                      </Button>
                    </InputGroup>
                    <small className="text-muted d-block mt-1">
                      Default number: {defaultContactNumber}
                    </small>
                  </FormGroup>
                </CardBody>
              </Card>
            </Collapse>
          </Col>
        </Row>

        <FormGroup>
          <Label for="message" className="text-muted fs-12">Message</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            innerRef={textareaRef}
            value={form.message}
            onChange={handleTextareaChange}
            // readOnly={type === 'sms'}
            // className={type === 'sms' ? 'bg-light' : ''}
            style={{ minHeight: '200px' }}
          />
        </FormGroup>

        <Row>
            <Col>
                <Button
                    color="light"
                    onClick={togglePlaceholder}
                    className="mb-1 d-flex align-items-center"
                    size="sm"
                    >
                    <span className="me-2">Available Placeholders</span>
                    <i className={`fas fa-chevron-${isPlaceholderOpen ? 'up' : 'down'}`}></i>
                </Button>

                <Collapse isOpen={isPlaceholderOpen}>
                    <FormGroup>
                        <Label className="text-muted fs-12">Available Placeholders</Label>
                        <div className="d-flex flex-wrap gap-2">
                            {placeholders.map(({ key, label }) => (
                            <Button
                                key={key}
                                color="secondary"
                                outline
                                size="sm"
                                onClick={() => insertPlaceholder(key)}
                                className="me-1"
                                title={`Click to insert {{${key}}}`}
                            >
                                {label}
                            </Button>
                            ))}
                        </div>
                        </FormGroup>
                </Collapse>
                
            </Col>
        </Row>

        </CardBody>

    </Card>
  );
};

export default MessageCompose;