export default {
    getEventCategories:{
        data:[],
        error:null,
        loading:false,
    },createEvent:{
        data:[],
        error:null,
        loading:false,
    },getEvents:{
        data:[],
        error:null,
        loading:false,
    },getTicketTypes:{
        data:[],
        error:null,
        loading:false,
    },getEventTicketTypes:{
        data:[],
        error:null,
        loading:false,
    },createEventTicketTypes:{
        data:[],
        error:null,
        loading:false,
    },orderEventTicketTypes:{
        data:[],
        error:null,
        loading:false,
    },getEventDetails:{
        data:[],
        error:null,
        loading:false,
    },generateInvitationCard:{
        data:[],
        error:null,
        loading:false,
    },getInvitationTemplates:{
        data:[],
        error:null,
        loading:false,
    },setEventTemplate:{
        data:[],
        error:null,
        loading:false,
    },shareEventCard:{
        data:[],
        error:null,
        loading:false,
    },uploadInvitationEditedTemplates:{
        data:[],
        error:null,
        loading:false,
    },eventApprovalState:{
        data:[],
        error:null,
        loading:false,
    },postRsvpState:{
        data:[],
        error:null,
        loading:false,
    },eventTicketCardInfo:{
        data:[],
        error:null,
        loading:false,
    },eventTicketCardVerification:{
        data:[],
        error:null,
        loading:false,
    },generateEventTableState:{
        data:[],
        error:null,
        loading:false,
    },eventTableState:{
        data:[],
        error:null,
        loading:false,
    },uploadBannerState:{
        data:[],
        error:null,
        loading:false,
    },getBannersState:{
        data:[],
        error:null,
        loading:false,
    },uploadCardTemplateState:{
        data:[],
        error:null,
        loading:false,
    },generateCardTemplateSampleState:{
        data:[],
        error:null,
        loading:false,
    },uploadSampleCardTemplateState:{
        data:[],
        error:null,
        loading:false,
    },downloadEventTicketTypeTemplateState:{
        data:[],
        error:null,
        loading:false,
    },uploadEventTicketTypeTemplateState:{
        data:[],
        error:null,
        loading:false,
    }
}