import React, { useContext, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { Button, Card, CardBody, CardFooter, CardText, Col, Form, FormFeedback, FormGroup, Input, Label, Row, Spinner, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCog, faTimesCircle, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { generateEventSamplecard, uploadTemplateSampleCover } from '../../../Actions/Masters/InvitationTemplatesActs';

const CardTemplateUploadManager = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [analyzedProperties, setAnalyzedProperties] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [form, setForm] = useState({ template_name: '',template_font_color:'black' });
    const [formErrors, setFormErrors] = useState({
        template_name: 'Template name is required',
        template_font_color: '',
    });
    const [imageURL, setImageURL] = useState(null);

    const {
        MasterDispatch,
        MasterState: {
            uploadSampleCardTemplateState: { loading },
            generateCardTemplateSampleState:{loading:generateLoading}
        }
    } = useContext(GlobalStore);

    const REQUIRED_WIDTH = 618;
    const REQUIRED_HEIGHT = 914;
    const MIN_FILE_SIZE = 26556; // 25 KB
    const MAX_FILE_SIZE = 464696; // 453 KB;
    const QUALITY_THRESHOLD = 0.8; // 80%
    const ALLOWED_TYPES = ["image/jpeg", "image/png"];

    const handleFileImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 1) {
            toast.error("You can only upload up to 1 image.");
            return;
        }

        const file = files[0];
        if (!file) return;

        if (!ALLOWED_TYPES.includes(file.type)) {
            toast.error("Only JPEG and PNG images are allowed.");
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
                const isDimensionValid = img.width === REQUIRED_WIDTH && img.height === REQUIRED_HEIGHT;
                const fileSizeKB = file.size / 1024;
                const isSizeValid = file.size >= MIN_FILE_SIZE && file.size <= MAX_FILE_SIZE;

                // Estimate image quality (rough approximation based on file size)
                const estimatedQuality = Math.min(file.size / 100000, 1);
                const isQualityValid = estimatedQuality >= QUALITY_THRESHOLD;

                const imageType = file.type.split('/')[1].toUpperCase(); // JPEG || PNG

                const newProperties = {
                    dimensions: `${img.width} x ${img.height}`,
                    isDimensionValid,
                    size: `${fileSizeKB.toFixed(2)} KB`,
                    isSizeValid,
                    quality: `${(estimatedQuality * 100).toFixed(0)}%`,
                    isQualityValid,
                    type: imageType,
                    isTypeValid: ALLOWED_TYPES.includes(file.type),
                };

                setAnalyzedProperties([newProperties]);
                setImagePreviews([reader.result]);
                setSelectedFiles([file]);

                // Update form validity based on all validations
                setFormValid(
                    newProperties.isDimensionValid &&
                    newProperties.isSizeValid &&
                    newProperties.isQualityValid &&
                    newProperties.isTypeValid &&
                    !!form.template_name &&
                    !!form.template_font_color
                );
            };
        };
    };

    const handleDelete = () => {
        setSelectedFiles([]);
        setImagePreviews([]);
        setAnalyzedProperties([]);
        setFormValid(false);
        setForm({template_name:'',template_font_color:''});
        setFormErrors({
            template_name: 'Template name is required',
            template_font_color: 'Template font color is required',
        })
    };

    const onChange = ({ name, value }) => {
        setForm((prev) => ({ ...prev, [name]: value }));
        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }

        // Revalidate form validity when template_name changes
        setFormValid(
            analyzedProperties.length > 0 &&
            analyzedProperties[0]?.isDimensionValid &&
            analyzedProperties[0]?.isSizeValid &&
            analyzedProperties[0]?.isQualityValid &&
            analyzedProperties[0]?.isTypeValid &&
            value
        );
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!formValid) return;

        const formData = new FormData();

        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        Object.keys(form).forEach(key => {
            formData.append(key, form[key]);
        });

        try {
            const response = await uploadTemplateSampleCover(formData)(MasterDispatch);

            if (response.status) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error(error.message || "An error occurred.");
        }
    };


    const generateSampleCard = async (e)=>{
        e.preventDefault(); // Prevent default

        // Ensure form validation before proceeding
        if (!formValid) {
            toast.error("Please ensure the form is valid before generating a sample card.");
            return;
        }

        const formData = new FormData();

        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        Object.keys(form).forEach((key) => {
            formData.append(key, form[key]);
        });

        try {
            const image = await generateEventSamplecard(formData)(MasterDispatch)
            setImageURL(image);
            toast.success("Sample card generated successfully.");
        } catch (error) {
            toast.error(error.message || "An error occurred while generating the sample card.");
        }
    }

    return (
        <Form onSubmit={onSubmit} encType="multipart/form-data">
            <Row>
                <Col xl={7}>
                    <div className="d-flex justify-content-between p-">

                        {selectedFiles.map((file, index) => (
                            <Card className="template-card rounded-0 p-0 border-0 shadow-sm" key={index}>
                                <CardBody className='p-0'>
                                    <div className="template-image-container">
                                        {imagePreviews[index] && (
                                            <img src={imagePreviews[index]} alt="Preview" className="template-image card-img-top" />
                                        )}
                                    </div>
                                    <CardText tag="h5" className='p-1 text-capitalize fs-12'>{file.name}</CardText>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        className='rounded-0 custom-close-button'
                                        onClick={handleDelete}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </CardBody>
                                <CardFooter className='bg-white p-0 border-0'></CardFooter>
                            </Card>
                        ))}

                        {imageURL && (
                        <Card className="template-card rounded-0 p-0 border-0 mt-3a ms-2 shadow-sm">
                            <CardBody className="p-0">
                                <div className="template-image-container">
                                    <img src={imageURL} alt="Generated Sample Card" className="template-image card-img-top" />
                                </div>
                                <CardText tag="h5" className="p-1 text-capitalize fs-12">
                                    Generated Sample Card
                                </CardText>
                            </CardBody>
                        </Card>
                    )}
                    </div>
                </Col>
                <Col xl={5}>
                    <FormGroup>
                        <Label for="fileUpload">Select Image / file</Label>
                        <Input
                            type="file"
                            size="sm"
                            id="fileUpload"
                            accept="image/png, image/jpeg"
                            onChange={handleFileImageChange}
                        />
                        <div className="mt-1">
                            <p>NOTE:</p>
                            <small className="form-text text-muted">
                                File size should be between <strong>25 KB</strong> and <strong>453 KB.</strong>
                            </small>
                            <br />
                            <small className="form-text text-muted">
                                Recommended image dimensions: <strong>618</strong> x <strong>914</strong> pixels.
                            </small>
                            <hr />
                        </div>

                        {analyzedProperties.length > 0 && (
                            <div className='mt-1'>
                                <Table size='sm' bordered className="caption-top fs-12">
                                    <caption>Image Properties:</caption>
                                    <thead>
                                        <tr>
                                            <th>Property</th>
                                            <th>Required</th>
                                            <th>Actual</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {analyzedProperties.map((prop, index) => (
                                            <>
                                                <tr key={`dimensions-${index}`}>
                                                    <td>Dimensions</td>
                                                    <td>{`${REQUIRED_WIDTH} x ${REQUIRED_HEIGHT}`}</td>
                                                    <td>{prop.dimensions}</td>
                                                    <td>
                                                        {prop.isDimensionValid
                                                            ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                                            : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />}
                                                    </td>
                                                </tr>
                                                <tr key={`quality-${index}`}>
                                                    <td>Quality</td>
                                                    <td>{`>= ${QUALITY_THRESHOLD * 100}%`}</td>
                                                    <td>{prop.quality}</td>
                                                    <td>
                                                        {prop.isQualityValid
                                                            ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                                            : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />}
                                                    </td>
                                                </tr>
                                                <tr key={`size-${index}`}>
                                                    <td>Size</td>
                                                    <td>{`Between ${MIN_FILE_SIZE / 1024} KB and ${MAX_FILE_SIZE / 1024} KB`}</td>
                                                    <td>{prop.size}</td>
                                                    <td>
                                                        {prop.isSizeValid
                                                            ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                                            : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />}
                                                    </td>
                                                </tr>
                                                <tr key={`type-${index}`}>
                                                    <td>Type</td>
                                                    <td>{`JPEG / PNG`}</td>
                                                    <td>{prop.type}</td>
                                                    <td>
                                                        {prop.isTypeValid
                                                            ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                                            : <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />}
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                    </FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="templateName">Template name</Label>
                                <Input
                                    type="text"
                                    placeholder='Write name..'
                                    className="rounded-0"
                                    onChange={(e) => onChange({ name: 'template_name', value: e.target.value })}
                                    value={form?.template_name}
                                    valid={formErrors.template_name === ''}
                                    invalid={formErrors.template_name !== ''}
                                />
                                {formErrors.template_name && <FormFeedback>{formErrors.template_name}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="templateName">Font Color</Label>
                                <Input
                                    type="select"
                                    className="rounded-0"
                                    onChange={(e) => onChange({ name: 'template_font_color', value: e.target.value })}
                                    value={form?.template_font_color}
                                    valid={formErrors.template_font_color === ''}
                                    invalid={formErrors.template_font_color !== ''}
                                >
                                    <option value="">--Select--</option>
                                    <option value="white">White</option>
                                    <option value="black">Black</option>
                                    {/* <option value="#FFFFFF">White</option>
                                    <option value="#000000">Black</option>
                                    <option value="#FF5733">Orange</option>
                                    <option value="#3498DB">Blue</option>
                                    <option value="#2ECC71">Green</option>
                                    <option value="#8E44AD">Purple</option>
                                    <option value="#E74C3C">Red</option>
                                    <option value="#F1C40F">Yellow</option>
                                    <option value="custom">Custom</option> */}
                                </Input>
                                {formErrors.template_font_color && <FormFeedback>{formErrors.template_font_color}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className="mt-2 form-group">
                        <Button
                            color="dark"
                            className="custom-button rounded-0"
                            disabled={!formValid}
                            size="sm">
                            {loading && <Spinner size="sm" />}
                            {!loading && <FontAwesomeIcon icon={faUpload} />} Upload
                        </Button>
                        <Button
                            color="dark"
                            className="custom-button rounded-0 ms-1"
                            disabled={!formValid || generateLoading}
                            onClick={generateSampleCard}
                            size="sm">
                            {generateLoading ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faCog} />} Generate sample card
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default CardTemplateUploadManager;
