import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChartBar, faCheck, faCheckCircle, faCloudArrowUp, faCog, faDashboard, faEraser, faFileImage, faIdCard, faMagnet, faPizzaSlice, faPlus, faSync, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import EventCreate from '../Components/Pages/Events/EventCreate';
import { GlobalStore } from '../StateStores';
import { clearEventCardInfoState, getEventCardInfo, postEventCardVerification } from '../Actions/Masters/EventCategoriesActs';
import { toast } from 'react-toastify';
import moment from 'moment';
import FoodMenuCategory from '../Components/Pages/FoodMenuManager/FoodMenuCategory';
import FoodMenuItems from '../Components/Pages/FoodMenuManager/FoodMenuItems';
import BannerManager from '../Components/Pages/BannerManager/BannerManager';
import UserBulkUploadManager from '../Components/Pages/Users/UserBulkUploadManager';
import { postBulkUsersAct } from '../Actions/Users/UsersActs';
import { userCan, userCanAny } from '../Helpers/permissionHandler';
import { BANNER_MANAGER, CREATE_EVENT_MENU, CREATE_EVENTS, CREATE_FOOD_MENU_CATEGORY, CREATE_FOOD_MENU_ITEMS, EVENT_APPROVALS, EVENT_CARD_TEMPLATE_TAB_RIGHTS, EVENT_MASTER_LIST, EVENT_TAB_RIGHTS, FOOD_MENU_TAB_RIGHTS, SYSTEM_ANALYTICS_TAB_RIGHTS, SYSTEM_SETUP_TAB_RIGHTS, UPLOAD_EVENT_CARD_TEMPLATES, USER_BULK_UPLOAD, VERIFY_CARD_CODE, VIEW_ALL_UPLOADED_EVENT_CARD_TEMPLATES, VIEW_CATEGORIES, VIEW_EVENT_CALENDAR, VIEW_EVENTS, VIEW_USERS, VIEW_USERSGROUPS, VIEW_VISITORS_ANALTICS_DASHBOARD } from '../Constants/PermissionTypes';
import CardTemplateUploadManager from '../Components/Pages/InvitationTemplates/CardTemplateUploadManager';

const Header = ()=>{
    const [modal, setModal] = useState(false);
    const [bannerModal, setBannerModal] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);
    const [foodCategoryModal, setFoodCategoryModal] = useState(false);
    const [foodMenuItemModal, setFoodMenuItemModal] = useState(false);
    const [userBulkModal, setUserBulkModal] = useState(false);
    const [cardUploadModal, setCardUploadModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [form,setForm] = useState({card_code:'',});
    const [formErrors,setFormErrors] = useState({card_code:'Card code is required',});

    //For card 
    const [imageBase64, setImageBase64] = useState("");
    const [hasDetails, setHasDetails] = useState(false);
    const [cardData, setCardData] = useState({});
    const [cardVerifications, setCardVerifications] = useState([]);

    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState([]);

    const {
        MasterDispatch,
        MasterState:{
            eventTicketCardInfo:{data,error,loading},
            eventTicketCardVerification:{data:vData,error:vError,loading:vloading}
        },
        UsersDispatch,
        UsersState:{
            postBulkUserUploadState:{data:resBulkData,loading:resBulkLoading},
        }
    } = useContext(GlobalStore);

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const togOpenBannerModal = ()=>{
        setBannerModal(!bannerModal);
    }

    const togOpenVerifyModal = ()=>{
        setVerifyModal(!verifyModal);
        ResetButton();
    }

    const togOpenFoodCategoryModal = ()=>{
        setFoodCategoryModal(!foodCategoryModal);
    }

    const togOpenFoodMenuItemModal = ()=>{
        setFoodMenuItemModal(!foodMenuItemModal);
    }

    const togOpenBulkModal = ()=>{
        setUserBulkModal(!userBulkModal);
    }

    const togOpenCardUploadModal = ()=>{
        setCardUploadModal(!cardUploadModal);
    }

    const ResetButton = ()=>{
        setHasDetails(false);
        clearEventCardInfoState(MasterDispatch);
        setCardData({})
        setCardVerifications([]);
        setImageBase64("");
        setForm({card_code:""})
    }

    const onChange = ({name,e}) => {
        let value = e.target.value;

        setForm((prev) => ({ ...prev, [name]: value }));

        if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }

    }

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (!form.card_code) {
            newErrors.card_code = 'This field is required';
            valid = false;
        }

        setFormErrors(newErrors);
        return valid;
    };

    const handleFetchCard = async ()=>{
        if (validateForm()) {
            try {
                const response = await getEventCardInfo(form)(MasterDispatch);
                if(response.status === 'Success'){
                    const {event,ticket,card,verifications} = response.data;

                    const base64String = `data:image/png;base64,${card}`;
                    setImageBase64(base64String);
                    setCardData(ticket)
                    setCardVerifications(verifications)
                    setHasDetails(true);
                }
            } catch (err) {
                toast.error(err.message);
            }
        }
    }

    const handleCardVerify = async ()=>{
        if(cardData){
            try {
                const response = await postEventCardVerification(cardData)(MasterDispatch);
                if(response.status){
                    toast.success(response.message);
                }else{
                    toast.error(response.message);
                }
            } catch (err) {
                toast.error(err?.response?.data?.message);
            }
        }
    }

    const handleFileUpload = async ()=>{
        const form = fileData.slice(1);

        try {
            const response = await postBulkUsersAct(form)(UsersDispatch);
            if(response.status){
                toast.success(response.message)
            }

            const statusIndex = fileData[0].indexOf('Status');

            const updatedFileData = fileData.map((row, index) => {
                if (index === 0) {
                    if (statusIndex === -1) {
                        return [...row, 'Status'];
                    }
                    return row;
                }

                // Assuming email is at index 6 and mobile is at index 5
                const userEmail = row[6];
                const userMobile = row[5];

                // Find the matching status using email or mobile
                const statusObj = response.data.find(
                    statusObj => 
                        statusObj.user[6] === userEmail || 
                        statusObj.user[5] === userMobile
                );

                const status = statusObj ? statusObj.status : 'skipped';

                if (statusIndex === -1) {
                    return [...row, status];
                } else {
                    // Override the status in the existing column
                    return [
                        ...row.slice(0, statusIndex), 
                        status, 
                        ...row.slice(statusIndex + 1) // Include the rest of the columns after 'Status'
                    ];
                }
            });

            setFileData(updatedFileData);
        } catch (error) {
            toast.error(error.message)
        }
    }



    return(
        <React.Fragment>
            <Modal className='rounded-0 custom-modal' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'><FontAwesomeIcon icon={faPlus}/> <span>Create Event</span> {selectedCategory?.name && ` - ${selectedCategory?.name}`}</h5>
                </ModalHeader>
                <ModalBody>
                    <Row className='g-3 p-3'>
                        <Col>
                            <EventCreate 
                                selectedCategory={selectedCategory} 
                                setSelectedCategory={setSelectedCategory}
                                ctrlModal={togOpenModal}/>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>

            <Modal isOpen={verifyModal} toggle={togOpenVerifyModal} size="sm" className='custom-modal'>
                <ModalHeader className='yellow-background border-0' toggle={togOpenVerifyModal}>Event card verification</ModalHeader>
                <ModalBody className="text-centers p-0">
                    {imageBase64 && <img src={imageBase64} alt="Invitation" className="img-fluid" />}
                    
                    {cardVerifications.length > 0 && (
                        <div className='p-2'>
                            <h6>Card verifications</h6>
                            <div className='mt-3'>

                                {(cardVerifications || []).map(verification => (
                                    <div className='d-flex justify-content-between border-bottom'>
                                        <div style={{width:'10%'}}><FontAwesomeIcon icon={faCheckCircle}/></div>
                                        <div style={{width:'90%'}}>
                                            <div><strong>{verification?.scanned_by}</strong></div>
                                            <small className='text-primary fs-9'>{moment(verification?.scan_timestamp).format('LL HH:mm:ssa')} ({moment(verification?.scan_timestamp).startOf().fromNow()})</small>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    )}

                    {!hasDetails && (
                        <div className='p-2'>
                            <Row>
                                <Col>
                                    <Label>Enter event card code</Label>
                                    <Input 
                                        type='text'
                                        placeholder='Card code'
                                        className="rounded-0"
                                        value={form?.card_code}
                                        onChange={(e) => onChange({name:'card_code',e})}
                                        valid={formErrors.card_code === ''}
                                        invalid={formErrors.card_code !== ''}
                                        />
                                    {formErrors.card_code && <FormFeedback>{formErrors.card_code}</FormFeedback>}
                                </Col>
                            </Row>
                        </div>
                    )}
                </ModalBody>
                {/* <ShareButtons ticket={ticketData}/> */}
                <div className="d-flex flex-columna w-100">
                    {hasDetails && (
                        <Button 
                            color="success" 
                            size='sm'
                            className='rounded-0 flex-grow-1 yellow-background fs-color-black border-0 d-flex align-items-center justify-content-center'
                            onClick={handleCardVerify}>
                                {vloading && <Spinner size="sm"/>}
                                {!vloading && <span className="icon-circle"><FontAwesomeIcon icon={faCheckCircle}/></span>}
                                <span className='ms-1'>Card Verify</span>
                        </Button>
                    )}

                    {!hasDetails && (
                        <Button 
                            color="success" 
                            size='sm'
                            className='rounded-0 flex-grow-1 yellow-background fs-color-black border-0 d-flex align-items-center justify-content-center'
                            onClick={handleFetchCard}>
                                {loading && <Spinner size="sm"/>}
                                {!loading && <span className="icon-circle"><FontAwesomeIcon icon={faSync}/></span>}
                                <span className='ms-1'>Fetch card</span>
                        </Button>
                    )}

                    <Button
                        outline 
                        color="danger"  
                        size='sm' className='rounded-0 flex-grow-1 black-backgrounds custom-button d-flex align-items-center justify-content-center' onClick={ResetButton}>
                            <span className="icon-circle yellow-background fs-color-black"><FontAwesomeIcon icon={faEraser}/></span>
                            <span className='ms-1'>Reset</span>
                    </Button>

                    <Button
                        outline 
                        color="danger"  
                        size='sm' className='rounded-0 flex-grow-1 black-backgrounds custom-button d-flex align-items-center justify-content-center' onClick={togOpenVerifyModal}>
                            <span className="icon-circle yellow-background fs-color-black"><FontAwesomeIcon icon={faTimes}/></span>
                            <span className='ms-1'>Close</span>
                    </Button>
                </div>
            </Modal>

            <Modal 
                isOpen={foodCategoryModal} 
                toggle={togOpenFoodCategoryModal} size="md" className='custom-modal'>
                <ModalHeader 
                    className='yellow-background border-0' 
                    toggle={togOpenFoodCategoryModal}>Food menu categories</ModalHeader>
                <ModalBody className="text-centers">
                    <FoodMenuCategory/>
                </ModalBody>
            </Modal>

            <Modal 
                isOpen={foodMenuItemModal} 
                toggle={togOpenFoodMenuItemModal} size="xl" className='custom-modal'>
                <ModalHeader 
                    className='yellow-background border-0' 
                    toggle={togOpenFoodMenuItemModal}>Food menu item</ModalHeader>
                <ModalBody className="text-centers">
                    <FoodMenuItems/>
                </ModalBody>
            </Modal>


            <Modal 
                isOpen={bannerModal} 
                toggle={togOpenBannerModal} size="xl" className='custom-modal'>
                <ModalHeader 
                    className='yellow-background border-0' 
                    toggle={togOpenBannerModal}>Banner Management Module</ModalHeader>
                <ModalBody className="text-centers">
                    <BannerManager/>
                </ModalBody>
            </Modal>

            <Modal 
                isOpen={userBulkModal} 
                toggle={togOpenBulkModal} fullscreen className='custom-modal'>
                <ModalHeader 
                    className='yellow-background border-0 p-2' 
                    toggle={togOpenBulkModal}><FontAwesomeIcon icon={faCloudArrowUp}/> User bulk upload</ModalHeader>
                <ModalBody className="text-centers">
                    <UserBulkUploadManager 
                        file={file} 
                        setFile={setFile} 
                        data={fileData} 
                        setData={setFileData}/>
                </ModalBody>
                <ModalFooter className='p-0'>
                    <Button 
                    color="dark"
                    className="custom-button rounded-0" 
                    onClick={handleFileUpload} 
                    disabled={!file}
                    >
                        {resBulkLoading && <Spinner size="sm"/>}
                        {!resBulkLoading && <FontAwesomeIcon icon={faUpload}/>} Upload data
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal 
                isOpen={cardUploadModal} 
                toggle={togOpenCardUploadModal} size="xl" className='custom-modal'>
                <ModalHeader 
                    className='yellow-background border-0' 
                    toggle={togOpenCardUploadModal}>Card template upload</ModalHeader>
                <ModalBody className="text-centers">
                    <CardTemplateUploadManager/>
                </ModalBody>
            </Modal>


            <div className="app-container  container-fluid d-flex align-items-stretch justify-content-between">
                <nav className="navbar navbar-expand-lg navbar-light shadow-sm bg-brand-primary fixed-top">
                    <div className="app-header-logo d-flex align-items-center ps-lg-2 me-lg-10">
                        <a className="navbar-brand d-flex align-items-center" href="#">
                            {/* <!-- <logo here --> */}
                            <div className="ml-3 app-logo">
                                <img src="/img/site/maxlogo.png" alt="" />
                            </div>
                        </a>

                        <button className="navbar-toggler navbar-toggler-right border-0" type="button" data-toggle="collapse" data-target="#navbar4">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 collapse navbar-collapse">
                        {/* <!--begin::Menu wrapper--> */}
                        <div className="d-flex align-items-stretch menu-state-dark">
                            <ul className="navbar-nav mr-auto pl-lg-4">
                                <li className="menu-item here px-lg-2 d-menu">
                                    <NavLink to="/dashboard/" className="menu-link">
                                        <span><FontAwesomeIcon icon={faDashboard}/></span>
                                        <span className='menu-title ms-1'>Dashboard</span>
                                    </NavLink>
                                </li>
                                
                                <li 
                                    className="menu-item here px-lg-2 d-menu dropdown"
                                    hidden={!userCanAny(SYSTEM_SETUP_TAB_RIGHTS)}
                                    >
                                    <a className="menu-link menu-link-padding dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown">
                                        <span><FontAwesomeIcon icon={faCog}/></span>
                                        <span className='menu-title ms-1'>System setup</span>
                                    </a>

                                    <div className="dropdown-menu rounded-0 yellow-background shadow-sm sm-menu">

                                        {userCan(VIEW_CATEGORIES) ? (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/categories" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Categories</span>
                                                </NavLink>
                                            </div>
                                        ) : null}

                                        {userCan(VIEW_USERS) ? (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/users" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Users</span>
                                                </NavLink>
                                            </div>
                                        ):null}
                                        
                                        {userCan(VIEW_USERSGROUPS) ? (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/users/groups" className="menu-link">
                                                    <span className="icon-ws menu-titlse">User groups</span>
                                                </NavLink>
                                            </div>
                                        ) : null}

                                        {userCan(USER_BULK_UPLOAD) ? (
                                            <div className="dropdown-item menu-item here">
                                                <a className="menu-link" onClick={()=> togOpenBulkModal()}>
                                                    <span className="icon-ws menu-titlse">User bulk upload</span>
                                                </a>
                                            </div>
                                        ) : null}

                                        {userCan(BANNER_MANAGER) ? (
                                            <div className="dropdown-item menu-item here">
                                                <a className="menu-link" onClick={()=> togOpenBannerModal()}>
                                                    <span className="icon-ws menu-titlse">Banner Manager</span>
                                                </a>
                                            </div>
                                        ) : null}

                                    </div>
                                </li>

                                <li 
                                    className="menu-item here px-lg-2 d-menu dropdown"
                                    hidden={!userCanAny(EVENT_TAB_RIGHTS)}
                                    >
                                    <a className="menu-link menu-link-padding dropdown-toggle">
                                        <span><FontAwesomeIcon icon={faCalendar}/></span>
                                        <span className='menu-title ms-1'>Event Manager</span>
                                    </a>

                                    <div className="dropdown-menu rounded-0 yellow-background shadow-sm sm-menu">

                                        {userCan(VIEW_EVENTS) ? (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/events" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Events</span>
                                                </NavLink>
                                            </div>
                                        ):null}

                                        {userCan(CREATE_EVENTS) ? (
                                            <div className="dropdown-item menu-item here">
                                                <a className="menu-link" onClick={()=> togOpenModal()}>
                                                    <span className="icon-ws menu-titlse">Create Event</span>
                                                </a>
                                            </div>
                                        ) : null}

                                        {userCan(VIEW_EVENT_CALENDAR) ? (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/events/calendar" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Calender</span>
                                                </NavLink>
                                            </div>
                                        ):null}

                                        {userCan(EVENT_APPROVALS) ? (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/events/approvals" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Event approvals</span>
                                                </NavLink>
                                            </div>
                                        ):null}

                                        {userCan(EVENT_MASTER_LIST) ? (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/events/masters" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Event master list</span>
                                                </NavLink>
                                            </div>
                                        ) : null}

                                        {userCan(VERIFY_CARD_CODE)? (
                                            <div className="dropdown-item menu-item here">
                                                <a className="menu-link" onClick={()=> togOpenVerifyModal()}>
                                                    <span className="icon-ws menu-titlse">Verify code</span>
                                                </a>
                                            </div>
                                        ) : null}

                                    </div>
                                </li>

                                <li 
                                    className="menu-item here px-lg-2 d-menu dropdown"
                                    hidden={!userCanAny(FOOD_MENU_TAB_RIGHTS)}
                                    >
                                    <a className="menu-link menu-link-padding dropdown-toggle">
                                        <span><FontAwesomeIcon icon={faPizzaSlice}/></span>
                                        <span className='menu-title ms-1'>Food menu Manager</span>
                                    </a>

                                    <div className="dropdown-menu rounded-0 yellow-background shadow-sm sm-menu">

                                        {userCan(CREATE_FOOD_MENU_CATEGORY) ? (
                                            <div className="dropdown-item menu-item here">
                                                <a className="menu-link" onClick={()=> togOpenFoodCategoryModal()}>
                                                    <span className="icon-ws menu-titlse">Categories</span>
                                                </a>
                                            </div>
                                        ) : null}

                                        {userCan(CREATE_FOOD_MENU_ITEMS) ? (
                                            <div className="dropdown-item menu-item here">
                                                <a className="menu-link" onClick={()=> togOpenFoodMenuItemModal()}>
                                                    <span className="icon-ws menu-titlse">Menu items</span>
                                                </a>
                                            </div>
                                        ):null}
                                        
                                    </div>
                                </li>

                                <li 
                                    className="menu-item here px-lg-2 d-menu dropdown"
                                    hidden={!userCanAny(EVENT_CARD_TEMPLATE_TAB_RIGHTS)}
                                    >
                                    <a className="menu-link menu-link-padding dropdown-toggle">
                                        <span><FontAwesomeIcon icon={faFileImage}/></span>
                                        <span className='menu-title ms-1'>Card Template Manager</span>
                                    </a>

                                    <div className="dropdown-menu rounded-0 yellow-background shadow-sm sm-menu">

                                        {userCan(VIEW_ALL_UPLOADED_EVENT_CARD_TEMPLATES)?  (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/card-templates" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Card templates</span>
                                                </NavLink>
                                            </div>
                                        ) : null}

                                        {userCan(UPLOAD_EVENT_CARD_TEMPLATES)?  (
                                            <div className="dropdown-item menu-item here">
                                                <a className="menu-link" onClick={()=> togOpenCardUploadModal()}>
                                                    <span className="icon-ws menu-titlse">Upload card template</span>
                                                </a>
                                            </div>
                                        ) : null}
                                        
                                    </div>
                                </li>
                                <li 
                                    className="menu-item here px-lg-2 d-menu dropdown"
                                    hidden={!userCanAny(SYSTEM_ANALYTICS_TAB_RIGHTS)}
                                    >
                                    <a className="menu-link menu-link-padding dropdown-toggle">
                                        <span><FontAwesomeIcon icon={faChartBar}/></span>
                                        <span className='menu-title ms-1'>System Analytics</span>
                                    </a>

                                    <div className="dropdown-menu rounded-0 yellow-background shadow-sm sm-menu">

                                        {userCan(VIEW_VISITORS_ANALTICS_DASHBOARD)?  (
                                            <div className="dropdown-item menu-item here">
                                                <NavLink to="/dashboard/analtics/visitors" className="menu-link">
                                                    <span className="icon-ws menu-titlse">Visitors Analytics Dashboard</span>
                                                </NavLink>
                                            </div>
                                        ) : null}
                                        
                                    </div>
                                </li>


                            </ul>
                        </div>
                        {/* <!--end::Menu wrapper--> */}

                        {/* <!--begin::profile bar--> */}
                        <div className="m-autos app-navbasr flex-shrisnk-0">
                            <div className="d-flex align-items-center">
                                {/* profile view */}
                                <ProfileDropdown/>
                                <div className='ms-2'></div>
                            </div>
                        </div>
                        {/* <!--end::profile bar--> */}
                    </div>
                </nav>
            </div>
            {/* <div className="wrapper-top"></div> */}

        </React.Fragment>
    )
}

export default Header;