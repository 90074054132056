import { faBullhorn, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Alert, Button, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { GlobalStore } from "../../../StateStores";
import { BroadcastToSender, downloadAttendeeListManager } from "../../../Actions/Masters/BroadcastManagerActs";
import { toast } from "react-toastify";
import MessageCompose from "../../Common/MessageCompose";
import Swal from "sweetalert2";

const BroadcastOptions = ({event,actionType,recipients})=>{
    const [form, setForm] = useState('');
    const [listType, setListType] = useState('');
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [sendingStatus, setSendingStatus] = useState(null);

    const {
        BroadcastDispatch,
        BroadcastState:{
            sendBroadCast:{loading},
            downloadListState:{loading:loadingDowload},
        }} = useContext(GlobalStore);

    const handleCheckboxChange = (recipient) => {
        setSelectedRecipients((prev) =>
            prev.includes(recipient)
                ? prev.filter((r) => r !== recipient)
                : [...prev, recipient]
        );
    };

    const handleSelectAll = () => {
        if (selectedRecipients.length === recipients.length) {
            setSelectedRecipients([]); // Uncheck all
        } else {
            setSelectedRecipients(recipients); // Check all
        }
    };

    const handleBroadcast = async () => {
        
        try {

            setSendingStatus({
                total: recipients.length,
                successful: 0,
                failed: 0
            });

            const response = await BroadcastToSender({
                ...form,
                event_date:event?.start,
                event_name:event?.event_name,
                channel:actionType,
                recipients:selectedRecipients
            })(BroadcastDispatch);

            if(response.status){
                toast.success(response.message)

                setSendingStatus({
                    total: response.details.total,
                    successful: response.details.successful.length,
                    failed: response.details.failed.length,
                    failedRecipients: response.details.failed
                });

                Swal.fire({
                    title: 'Broadcast Results',
                    html: `
                        <div>
                            <p class="text-success">Successfully sent: ${response.details.successful.length}</p>
                            <p class="text-danger">Failed: ${response.details.failed.length}</p>
                            <div class="mt-3">
                                <small>Failed Recipients:</small>
                                ${response.details.failed.map(fail => 
                                    `<small class="text-danger d-block">${fail.mobile}: ${fail.error}</small>`
                                ).join('')}
                            </div>
                        </div>
                    `,
                    icon: 'warning'
            });


            }
        } catch (error) {
            toast.error(error.message)
        }
    };

    const handleDownloadList = async () => {
        try {
            const response = await downloadAttendeeListManager({
                event_date: event?.start,
                event_name: event?.event_name,
                channel: actionType,
                type: listType,
                recipients: selectedRecipients
            })(BroadcastDispatch);

            if (response.status) {
                toast.success(response.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const renderHeaderTypeName = (key)=>{
        switch (key) {
            case 'whatsapp':
                return 'Broadcast via WhatsApp';
            case 'sms':
                return 'Broadcast via SMS';
            case 'reminder':
                return 'Broadcast via SMS Reminder';
            case 'download':
                return 'Download Manager';
            default:
                break;
        }
    }

    return(
        <React.Fragment>
            <div className="d-flex flex-column h-100">
                <div className="p-3">
                    <h6>{renderHeaderTypeName(actionType)}</h6>

                    {/* <Alert color="success" className="mb-3">
                        Messages sent successfully!
                        {sendingStatus && (
                        <div className="mt-2">
                            <small>
                            Successfully sent: {sendingStatus.successful} / {sendingStatus.total}
                            {sendingStatus.failed > 0 && (
                                <div className="text-danger">
                                Failed: {sendingStatus.failed}
                                {sendingStatus.failedRecipients && (
                                    <ul className="mt-1">
                                    {sendingStatus.failedRecipients.map((fail, index) => (
                                        <li key={index}>
                                        {fail.mobile}: {fail.error}
                                        </li>
                                    ))}
                                    </ul>
                                )}
                                </div>
                            )}
                            </small>
                        </div>
                        )}
                    </Alert> */}


                    <Form>

                        {['whatsapp', 'sms', 'reminder'].includes(actionType) && (
                            <MessageCompose type={actionType} form={form} setForm={setForm}/>
                        )}

                        {['download'].includes(actionType) && (
                            <FormGroup>
                                <Label for="message" className="text-muted fs-12">File type</Label>
                                <Input
                                    type="select"
                                    id="downloadId"
                                    readOnly={actionType === 'sms'}
                                    className={actionType === 'sms' && 'bg-light'}
                                    value={listType}
                                    size="sm"
                                    onChange={(e) => setListType(e.target.value)}
                                >
                                    <option>--Select--</option>
                                    <option value="attendee_list">Attendee List</option>
                                    <option value="attendee_event_cards">Attendee Event Cards</option>
                                </Input>
                            </FormGroup>
                        )}

                        
                    </Form>
                </div>

                <div className="flex-grow-1 overflow-auto px-3">
                    <FormGroup>
                        <div className="d-flex justify-content-between">
                            <Label>Recipients</Label>
                            <Button color="link" onClick={handleSelectAll}>
                                {selectedRecipients.length === recipients.length ? "Unselect All" : "Select All"}
                            </Button>
                        </div>
                        <ListGroup>
                            {recipients.map((recipient) => (
                                <ListGroupItem key={recipient.user_ticket_id} className="d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        onChange={() => handleCheckboxChange(recipient)}
                                        checked={selectedRecipients.includes(recipient)}
                                        className="me-2"
                                    />
                                    <div>
                                        <div><strong>{recipient.sold_by}</strong></div>
                                        <small className="text-muted">{recipient.mobile}</small><br/>
                                        <small className="text-muted">{recipient.card_code}</small>
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </FormGroup>
                </div>

                <div className="mt-auto p-3a border-top">

                    {['whatsapp', 'sms', 'reminder'].includes(actionType) && (
                        <Button 
                            color="primary"
                            size='sm' 
                            disabled={selectedRecipients.length === 0}
                            className='w-100 rounded-0 yellow-background fs-color-black border-0'
                            onClick={handleBroadcast}
                            >
                                {loading && <Spinner size="sm"/>}
                                {!loading && <span className="icon-circle"><FontAwesomeIcon icon={faBullhorn}/></span>}
                                <span className='ms-1'>Broadcast</span>
                        </Button>
                    )}

                    {['download'].includes(actionType) && (
                        <Button 
                            color="primary"
                            size='sm' 
                            disabled={selectedRecipients.length === 0}
                            className='w-100 rounded-0 yellow-background fs-color-black border-0'
                            onClick={handleDownloadList}
                            >
                                {loadingDowload && <Spinner size="sm"/>}
                                {!loadingDowload && <span className="icon-circle"><FontAwesomeIcon icon={faDownload}/></span>}
                                <span className='ms-1'>Download</span>
                        </Button>
                    )}
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default BroadcastOptions;