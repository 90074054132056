import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Form } from "react-router-dom";
import { toast } from "react-toastify";
import { Badge, Button, Card, CardBody, CardFooter, CardText, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { GlobalStore } from "../../../StateStores";
import { uploadTemplateCover } from "../../../Actions/Masters/InvitationTemplatesActs";
import moment from "moment";
import { SERVER_BASE_URL } from "../../../Helpers/AxiosHelper";

const InvitationTemplateUpload = ({event})=>{
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [formValid, setFormValid] = useState(false);

    const {
        MasterDispatch,
        MasterState:{
            uploadInvitationEditedTemplates:{data,error,loading},
        }} = useContext(GlobalStore);

    /**
     * handle the selection of image
     * @param {Event} e - Node element
     */
    const handleFileImageChange = (e) => {
        let selectedImage = e.target.files[0]
        const allowedTypes = ["image/jpeg","image/png"];

        if(!allowedTypes.includes(selectedImage?.type)){
            toast.error("Only JPEG and PNG images are allowed.");
        }else{
            const MIN_FILE_SIZE = 26556 // 25 KB
            const MAX_FILE_SIZE = 464696 // 453 KB

            if (MAX_FILE_SIZE >= selectedImage?.size) {
                const files = Array.from(e.target.files);
                //Create the copy
                const newSelectedFiles = [...selectedFiles, ...files];
                //Validation
                if (newSelectedFiles.length > 4) {
                    toast.error("You can only upload up to 4 images.");
                } else {
                    // Read and preview images
                    const previews = files.map((file) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            setImagePreviews([...imagePreviews, reader.result]);
                        };
                    });
                    setSelectedFiles(newSelectedFiles);
                    setFormValid(newSelectedFiles.length > 0);
                }
                
            }else{
                toast.error("File size is too large.");
            }
        }

    };

    /**
     * Delete the image / cover
     * @param {Number} index - selected imagae
     */
    const handleDelete = (index) => {
        const updatedFiles = [...selectedFiles];
        const updatedPreviews = [...imagePreviews];
        updatedFiles.splice(index, 1);
        updatedPreviews.splice(index, 1);
        setSelectedFiles(updatedFiles);
        setImagePreviews(updatedPreviews);
        setFormValid(updatedFiles.length > 0);
      };

      const onSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload

        const formData = new FormData();

        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        // Append product data as fields to the FormData object
        Object.keys(event).forEach(key => {
            formData.append(key, event[key]);
        });

        if(formValid){
            try {
                //Post the image to the server
                const response = await uploadTemplateCover(formData)(MasterDispatch);
    
                if(response.status){
                    toast.success(response.message);
                }else{
                    toast.error(response.message);
                }
    
            } catch (error) {
                toast.error(error);
            }
        }

        
      }



    return(
        <React.Fragment>
            <div className="mb-5">
                <Form onSubmit={onSubmit} encType="multipart/form-data">
                    <Row>
                        <Col xl={8}>

                            <div className="d-flex justify-content-center p-">

                                {event?.edited_template_cover && event.edited_template_cover_by && (
                                    <Card className="template-card rounded-0 p-0 border-0">
                                        <CardBody className='p-0'>
                                            <div className="template-image-container">
                                                <img
                                                    src={`${SERVER_BASE_URL}/cdn-template-sample-edited/${encodeURIComponent(event.edited_template_cover)}`}
                                                    crossorigin="anonymous"
                                                    alt="preview" className="template-image card-img-top" />
                                            </div>
                                            <CardText className='p-1 text-capitalize fs-12'>{event.edited_template_cover}</CardText>
                                            <Button 
                                                size="sm" 
                                                color="danger"
                                                className='rounded-0 custom-close-button'
                                                // onClick={() => handleDelete(index)}
                                                >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                        </CardBody>
                                        <CardFooter className='bg-white p-0 border-0'></CardFooter>
                                    </Card>
                                )}


                                {selectedFiles.map((file,index)=>(
                                    <Card className="template-card rounded-0 p-0 border-0">
                                        <CardBody className='p-0'>
                                            <div className="template-image-container">
                                            {imagePreviews[index] && 
                                                    <img src={imagePreviews[index]} alt="Preview" className="template-image card-img-top" />}
                                            </div>
                                            <CardText className='p-1 text-capitalize fs-12'>{file.name}</CardText>
                                            <Button 
                                                size="sm" 
                                                color="danger"
                                                className='rounded-0 custom-close-button'
                                                onClick={() => handleDelete(index)}>
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                        </CardBody>
                                        <CardFooter className='bg-white p-0 border-0'></CardFooter>
                                    </Card>
                                ))}
                            </div>

                        </Col>
                        <Col xl={4}>
                            <h5 className="text-capitalize mb-0">{event?.template_name}</h5>
                            <p className="fs-">{event?.description}</p>
                            <div>
                                <small className="text-muted fs-12"><strong>File: </strong>{event?.template_image}</small>
                            </div>
                            <div>
                                <small className="text-muted fs-12"><strong>Created By: </strong> <span className="text-primary">{event.createdby}</span></small>
                            </div>
                            <Badge className='bg-light text-muted'>
                                <small className="text-muted">{moment(event.doc).format('LL HH:mm:ssa')}</small>
                                <small className="text-info text-muted ms-1">({moment(event.doc).startOf().fromNow()})</small>
                            </Badge>

                            {event?.edited_template_cover === null && event.edited_template_cover_by === null && (
                                <React.Fragment>
                                    <div className="mt-3">
                                        <FormGroup className="">
                                            <Label for="fileUpload">Select Images</Label>
                                            <Input type="file" 
                                                size="sm"
                                                id="fileUpload" 
                                                multiple 
                                                accept="image/png, image/jpeg"
                                                onChange={handleFileImageChange} />
                                        </FormGroup>
                                    </div>
                                    <div className="mt-2 form-group">
                                        {/* disabled={!formValid} */}
                                        <div className="col-md-2s">
                                            <Button 
                                            color="dark" 
                                            className="custom-button rounded-0" 
                                            disabled={!formValid}
                                            size="sm">
                                                {loading && <Spinner size="sm"/>}
                                                {!loading && <FontAwesomeIcon icon={faUpload}/>} Upload</Button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                        </Col>
                    </Row>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default InvitationTemplateUpload;