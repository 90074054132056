import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Card, CardBody, CardTitle, Row, Col, Input, Spinner, CardHeader } from 'reactstrap';
import Header from '../../../Layouts/Header';
import { GlobalStore } from '../../../StateStores';
import { getSystemVisitors, getSystemVisitorStats } from '../../../Actions/Masters/AnalyticsActs';
import moment from 'moment';

const VisitorsAnalytics = () => {
    const [visitors, setVisitors] = useState([]);
    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');

    const {MasterDispatch} = useContext(GlobalStore);

    const fetchVisitors = async () => {
        try {
            const res = await getSystemVisitors()(MasterDispatch);
            if(res.status){
                setVisitors(res.data);
            }
        } catch (error) {
            console.error('Error fetching visitors:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStats = async () => {
        try {
            const res = await getSystemVisitorStats()(MasterDispatch)
            if(res.status){
                setStats(res.data);
            }
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    // Fetch visitors & stats
    useEffect(() => {
        fetchVisitors();
        fetchStats();
    }, []);

    // Search filter
    const filteredVisitors = visitors.filter(visitor =>
        visitor.ip_address.includes(search) || visitor.city.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>
            <Container className="mt-4" fluid>
                <Row>
                    {/* Stats Cards */}
                    <Col md="4">
                        <Card className="text-center border-0">
                            <CardBody>
                                <CardTitle>Total Visits</CardTitle>
                                <h2>{stats.totalVisits || 0}</h2>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="text-center border-0">
                            <CardBody>
                                <CardTitle>Unique Visitors</CardTitle>
                                <h2>{stats.uniqueVisitors || 0}</h2>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="text-center border-0">
                            <CardBody>
                                <CardTitle>Recent Visitors</CardTitle>
                                <h2>{stats.recentVisitors || 0}</h2>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Card className="border-0 mt-3">
                    <CardHeader>
                        <div className="d-flex justify-content-between">
                            {/* Search Box */}
                            <Row>
                                <Col>
                                    <Input
                                        type="text"
                                        placeholder="Search by IP or City..."
                                        value={search}
                                        size="sm"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />

                                    {loading && (
                                        <div className='d-flex justify-content-endd p-3'>
                                            <Spinner color='dark' size="sm"/>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </CardHeader>
                    <CardBody className="bg-light-f1 rounded-1 p-1">
                        {/* Visitors Table */}
                        <Table bordered responsive size='sm'>
                            <thead className='bg-light'>
                                <tr>
                                    <th>#</th>
                                    <th>IP Address</th>
                                    <th>User Agent</th>
                                    <th>Country</th>
                                    <th>City</th>
                                    <th>Region</th>
                                    <th>Last Visit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading && filteredVisitors.length > 0 ? (
                                    filteredVisitors.map((visitor, index) => (
                                        <tr key={visitor.idPrimary}>
                                            <td>{index + 1}</td>
                                            <td>{visitor.ip_address}</td>
                                            <td>{visitor.user_agent}</td>
                                            <td>{visitor.country}</td>
                                            <td>{visitor.city}</td>
                                            <td>{visitor.region}</td>
                                            <td>
                                                <div className="d-flex">
                                                    <div className="bg-light text-muted p-1 rounded">
                                                        <small className="text-muted">
                                                            {moment.utc(visitor.last_visit).format('LL, LT')}
                                                        </small>
                                                        <small className="text-info ms-1">
                                                            ({moment.utc(visitor.last_visit).fromNow()})
                                                        </small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">No visitors found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </CardBody>

                </Card>

            </Container>
        </React.Fragment>
    );
};

export default VisitorsAnalytics;
