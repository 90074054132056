export default {
    sendBroadCast:{
        data:[],
        error:null,
        loading:false,
    },
    downloadListState:{
        data:[],
        error:null,
        loading:false,
    }
}